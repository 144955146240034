/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        var isiOS = false;
        var isIE = /*@cc_on!@*/ false || !!document.documentMode,
          isEdge = !isIE && !!window.StyleMedia;

        // JavaScript to be fired on all pages

        /* Check if IE/Edge or iOS
        ========================================================================== */
        if (isIE || isEdge) {
          $("body").addClass("is-ie");
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          isiOS = true;
          $("body").addClass("is-ios");
        }

        /* Init Wow
        ========================================================================== */
        wow = new WOW({
          boxClass: "wow", // default
          animateClass: "animated", // default
          offset: 0, // default
          mobile: true, // default
          live: true, // default
        });

        wow.init();

        // =====================================================================
        //  Filter toggler on mobile
        // =====================================================================

        var filterToggler = $(".js-search-toggler"),
          filterMenu = $(".js-filter");

        filterToggler.on("click", function () {
          if (filterMenu.hasClass("is-closed")) {
            filterToggler.removeClass("is-closed").addClass("is-open");
            filterMenu.removeClass("is-closed").slideDown(600);
          } else {
            filterToggler.removeClass("is-open").addClass("is-closed");
            filterMenu.addClass("is-closed").slideUp(600);
          }
        });

        // =====================================================================
        // Slider (Swiper) Inits
        // =====================================================================
        var storiesStories = new Swiper(".js-stories-stories-slider", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-stories-ovr-nav-next",
            prevEl: ".js-slider-stories-ovr-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var homeStories = new Swiper(".js-home-stories-slider", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-stories-nav-next",
            prevEl: ".js-slider-stories-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var homeAlumni = new Swiper(".js-home-alumni-slider", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-alumni-nav-next",
            prevEl: ".js-slider-alumni-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var homeCourses = new Swiper(".js-home-courses-slider", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-courses-nav-next",
            prevEl: ".js-slider-courses-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var homeStories = new Swiper(".js-course-materials-slider", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-course-materials-nav-next",
            prevEl: ".js-slider-course-materials-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var courseKeynote = new Swiper(".js-slider-keynotes", {
          speed: 400,
          slidesPerView: 3,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-keynotes-nav-next",
            prevEl: ".js-slider-keynotes-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var courseInnovations = new Swiper(".js-slider-innovations", {
          speed: 400,
          slidesPerView: 3,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-innovations-nav-next",
            prevEl: ".js-slider-innovations-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var courseElearning = new Swiper(".js-slider-elearning", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-elearning-nav-next",
            prevEl: ".js-slider-elearning-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var teacherSlider = new Swiper(".js-slider-teacher", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-teacher-nav-next",
            prevEl: ".js-slider-teacher-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var boardSlider = new Swiper(".js-slider-board", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-board-nav-next",
            prevEl: ".js-slider-board-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var researchVideos = new Swiper(".js-research-videos", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-research-videos-nav-next",
            prevEl: ".js-slider-research-videos-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        var personalReflections = new Swiper(".js-personal-reflections", {
          speed: 400,
          slidesPerView: 4,
          spaceBetween: 15,
          navigation: {
            nextEl: ".js-slider-personal-reflections-nav-next",
            prevEl: ".js-slider-personal-reflections-nav-prev",
          },
          breakpoints: {
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          },
        });

        // Function that actually builds the swiper
        function buildSwiperSlider(sliderElm, index) {
          var loopIndex = index + 1;
          //   var sliderIdentifier = sliderElm.dataset.id;
          // console.log(
          //   "`js-slider-elearning${sliderIdentifier}`: ",
          //   `js-slider-elearning${loopIndex}`
          // );
          return new Swiper(".js-slider-elearning" + loopIndex, {
            speed: 400,
            slidesPerView: 4,
            spaceBetween: 15,
            navigation: {
              nextEl: ".js-slider-elearning" + loopIndex + "-nav-next",
              prevEl: ".js-slider-elearning" + loopIndex + "-nav-prev",
            },
            breakpoints: {
              480: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            },
          });
        }

        // Get all of the swipers on the page
        var allSliders = document.querySelectorAll(
          ".js-slider-group-elearnings"
        );
        console.log("test");

        if (allSliders) {
          console.log("allSliders: ", allSliders);
          // Loop over all of the fetched sliders and apply Swiper on each one.
          allSliders.forEach(buildSwiperSlider);
          // allSliders.forEach(buildSwiperSlider);
        }

        // =====================================================================
        //  Show alumni button on last slide
        // =====================================================================

        homeAlumni.on("slideChangeTransitionStart", function () {
          if (homeAlumni.isEnd) {
            $(".js-slider-btn-alumni").addClass("is-visible");
          } else {
            $(".js-slider-btn-alumni").removeClass("is-visible");
          }
        });

        // =====================================================================
        // Intro Block Read More Toggler
        // =====================================================================

        var readMoreToggler = $(".js-intro-content-more-toggle"),
          readMoreContent = $(".js-intro-content"),
          readMoreLess = $(".js-less"),
          readMoreMore = $(".js-more"),
          readMoreIcon = $(".js-intro-content-more-icon");

        readMoreToggler.on("click", function () {
          console.log("click");
          if (readMoreContent.is(":hidden")) {
            console.log("hidden");
            readMoreContent.slideToggle();
            readMoreLess.removeClass("is-hidden");
            readMoreMore.addClass("is-hidden");
            readMoreIcon.addClass("is-open");
          } else {
            readMoreContent.slideToggle();
            readMoreLess.addClass("is-hidden");
            readMoreMore.removeClass("is-hidden");
            readMoreIcon.removeClass("is-open");
          }
        });

        // =====================================================================
        // Footer Read More Toggle
        // =====================================================================

        var footerReadMoreToggler = $(".js-footer-content-more-toggle"),
          footerReadMoreContent = $(".js-footer-content-more"),
          footerReadMoreLess = $(".js-footer-less"),
          footerReadMoreMore = $(".js-footer-more"),
          footerReadMoreIcon = $(".js-footer-content-more-icon");

        footerReadMoreToggler.on("click", function () {
          if (footerReadMoreContent.is(":hidden")) {
            footerReadMoreContent.slideToggle();
            footerReadMoreLess.removeClass("is-hidden");
            footerReadMoreMore.addClass("is-hidden");
            footerReadMoreIcon.addClass("is-open");
          } else {
            footerReadMoreContent.slideToggle();
            footerReadMoreLess.addClass("is-hidden");
            footerReadMoreMore.removeClass("is-hidden");
            footerReadMoreIcon.removeClass("is-open");
          }
        });

        // =====================================================================
        // Book Download Handler
        // =====================================================================

        var currentLang = $(".js-doc-current"),
          currentLangTitle = $(".js-doc-current-title"),
          langList = $(".js-doc-lang-list"),
          langItem = $(".js-doc-lang-list-item"),
          dlToggle = $(".js-doc-dl");

        function langListStateOpen() {
          if (langList.hasClass("is-hidden")) {
            langList.removeClass("is-hidden").addClass("is-visible");
            currentLang.addClass("list-is-open");
          }
        }

        function langListStateClose() {
          if (langList.hasClass("is-visible")) {
            langList.removeClass("is-visible").addClass("is-hidden");
            currentLang.removeClass("list-is-open");
          }
        }

        $(document).on("click", ".js-doc-current", function (e) {
          e.stopPropagation();
          langListStateOpen();
        });

        $(document).on("click", ".js-doc-lang-list-item", function (e) {
          e.stopPropagation();
          currentLang.data("current-lang", $(this).data("lang"));
          currentLangTitle.html($(this).data("lang"));
          dlToggle.attr("href", $(this).data("lang-url"));
          langListStateClose();
        });

        // Closes on outer click
        $(window).on("click", function (e) {
          e.stopPropagation();
          if (
            !$(e.target).hasClass("js-doc-current") ||
            !$(e.target).hasClass("js-doc-lang-list") ||
            !$(e.target).hasClass("js-doc-lang-list-item")
          ) {
            langListStateClose();
          }
        });

        // =====================================================================
        // Video Modal Handler
        // =====================================================================

        function closeVideoModal(obj) {
          if ($(".js-video-wrapper").hasClass("is-visible")) {
            $(".js-video-wrapper")
              .removeClass("is-visible")
              .addClass("is-hidden");
            $(".js-shade").removeClass("is-visible").addClass("is-hidden");
            $(".js-video-inner").addClass("is-loading").empty();
            $("body").removeClass("video-modal-is-active");
          }
        }

        $(".js-modal-video-close").on("click", function () {
          closeVideoModal();
        });

        $(".js-shade").on("click", function () {
          closeVideoModal();
        });

        // =====================================================================
        //  Panel Handler
        // =====================================================================

        $(document).on("click", ".js-panel-toggler", function () {
          // Close all siblings
          $(this).parent().siblings().find(".js-panel-content").slideUp();
          $(this)
            .parent()
            .siblings()
            .removeClass("is-open")
            .addClass("is-closed");

          // Handle states
          if ($(this).parent().hasClass("is-closed")) {
            $(this).parent().removeClass("is-closed").addClass("is-open");
          } else {
            $(this).parent().addClass("is-closed").removeClass("is-open");
          }

          $(this).parent().find(".js-panel-content").slideToggle();
        });

        // =====================================================================
        //  Load More Handler
        // =====================================================================

        $(document).on("click", ".js-load-more", function () {
          if ($(this).hasClass("is-not-loaded")) {
            $(this).parent(".js-has-more").find(".js-item").slideDown();
            $(this).removeClass("is-not-loaded").addClass("is-loaded");
            $(this).find(".js-load-more-text").html("Load less");
          } else {
            $(this).parent(".js-has-more").find(".js-item").slideUp();
            $(this).find(".js-load-more-text").html("Load more");
            $(this).addClass("is-not-loaded").removeClass("is-loaded");
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    page_template_page_upload: {
      init: function () {
        // Replace text on acf forms
        $('select option:contains("- Select -")').text("Please select");
        $(".acf-js-tooltip.-minus").attr("title", "Remove");
      },
    },
  };

  /* Navbar Menu Modal Handling
  /* ======================================================================== */

  var menu = $(".js-menu"),
    menuToggle = $(".js-menu-toggle"),
    menuToggleClose = $(".js-menu-toggle-close"),
    menuOpenState = false;

  function openMenuModal() {
    menu.removeClass("is-closed").addClass("is-open");
    menuOpenState = true;
    $("body").addClass("modal-is-visible");
  }

  function closeMenuModal() {
    menu.removeClass("is-open").addClass("is-closed");
    menuOpenState = false;
    $("body").removeClass("modal-is-visible");
  }

  menuToggle.on("click", function (e) {
    e.preventDefault();
    openMenuModal();
  });

  menuToggleClose.on("click", function (e) {
    e.preventDefault();
    closeMenuModal();
  });

  //* Navbar GSLA Dropdown
  /* ======================================================================== */

  var menuNavbar = $(".js-programs-courses-training"),
    menuDropIcon = $(".js-menu-content-drop-icon"),
    menuDrop = $(".js-menu-drop");

  function openMenuDrop() {
    menuDrop.removeClass("is-hidden").addClass("is-visible");
  }

  function closeMenuDrop() {
    menuDrop.removeClass("is-visible").addClass("is-hidden");
  }

  menuNavbar.on("click", function () {
    if (menuDrop.is(":hidden")) {
      menuDrop.slideToggle();
      openMenuDrop();
      menuDropIcon.removeClass("is-closed").addClass("is-open");
    } else {
      menuDrop.slideToggle();
      closeMenuDrop();
      menuDropIcon.removeClass("is-open").addClass("is-closed");
    }
    // slideToggle();
    // menuNavbar.removeClass('is-hidden').addClass('is-visible');
    // menuDropIcon.removeClass('is-hidden').addClass('is-open');
  });

  $(".js-programs-courses-training ul").click(function (e) {
    e.stopPropagation();
  });

  menuNavbar.on("click", function (e) {
    e.preventDefault();
  });

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
